import account from "app.reducers/account";
import authentication from "app.reducers/authentication";
import computables from "app.reducers/computables";
import exchangeLedger from "app.reducers/exchangeLedger";
import exchanges from "app.reducers/exchanges";
import organizations from "app.reducers/organizations";
import portfolios from "app.reducers/portfolios";
import smartWallet from "app.reducers/smartWallet";
import ui from "app.reducers/ui";
import user from "app.reducers/user";
import files from "app.reducers/files";
import enrollment from "app.reducers/enrollment";

// Reducers are responsible for a slice of the application state. When an action happens, if
// A reducer handles that action, it can operate on its slice of the state and return a new copy
// of its slice

// A reducer is a pure function which takes in as an argument, State and Action. The action
// tells the reducer how to manipulate state, or, which portion of state to return

// combineReducers is how Redux manages splitting application state logically.
// Combine reducers will return a single redux state from the result of combining multiple redux reducers.

// combineReducers will be handled internally by configureStore inside @reduxjs/toolkit

export const rootReducer = {
  account,
  authentication,
  computables,
  exchangeLedger,
  exchanges,
  organizations,
  portfolios,
  smartWallet,
  ui,
  user,
  files,
  enrollment,
};
