import { takeEvery, takeLeading } from "redux-saga/effects";
import * as organizationActions from "app.reducers/organizations";

import addTeamMember from "./addTeamMember";
import addProfile from "./addProfile";
import assumeProfile from "./assumeProfile";
import deleteTeamMember from "./deleteTeamMember";
import deleteProfile from "./deleteProfile";
import enterTeamMemberSection from "./enterTeamMemberSection";
import fetchTeamMemberList from "./fetchTeamMemberList";
import fetchOrganization from "./fetchOrganization";
import reinviteTeamMember from "./reinviteTeamMember";
import reinviteUser from "./reinviteUser";
import inviteUser from "./inviteUser";
import unassumeProfile from "./unassumeProfile";
import updateProfile from "./updateProfile";
import updateTeamMemberAccess from "./updateTeamMemberAccess";
import enterSettingsSection from "./enterSettingsSection";
import undoDeleteProfile from "./undoDeleteProfile";
import fetchAccountingPreferences from "./fetchAccountingPreferences";
import saveAccountingPreferences from "./saveAccountingPreferences";

// WATCHERS
const organizationSagas = [
  takeEvery(organizationActions.assumeProfile, assumeProfile),
  takeLeading(
    organizationActions.enterTeamMemberSection,
    enterTeamMemberSection
  ),
  takeLeading(organizationActions.fetchTeamMemberList, fetchTeamMemberList),
  takeEvery(organizationActions.addTeamMember, addTeamMember),
  takeEvery(organizationActions.deleteTeamMember, deleteTeamMember),
  takeEvery(organizationActions.updateTeamMemberAccess, updateTeamMemberAccess),
  takeEvery(organizationActions.fetchOrganization, fetchOrganization),
  takeEvery(organizationActions.inviteUser, inviteUser),
  takeEvery(organizationActions.addProfile, addProfile),
  takeEvery(organizationActions.deleteProfile, deleteProfile),
  takeEvery(organizationActions.undoDeleteProfile, undoDeleteProfile),
  takeEvery(organizationActions.updateProfile, updateProfile),
  takeEvery(organizationActions.reinviteUser, reinviteUser),
  takeEvery(organizationActions.reinviteTeamMember, reinviteTeamMember),
  takeEvery(organizationActions.unassumeProfile, unassumeProfile),
  takeLeading(organizationActions.enterSettingsSection, enterSettingsSection),
  takeEvery(
    organizationActions.fetchOrgAccountingPreferences,
    fetchAccountingPreferences
  ),
  takeEvery(
    organizationActions.saveAccountingPreferences,
    saveAccountingPreferences
  ),
];

// export all watcher sagas as an array to be composed in the top level
// root saga
export default organizationSagas;
