import { select, put } from "redux-saga/effects";

import { resetSmartWalletSection } from "app.reducers/smartWallet";
import fetchSmartWalletRange from "./fetchSmartWalletRange";
import fetchSmartWalletSummary from "./fetchSmartWalletSummary";

function* fetchSmartWalletData(action) {
  const smartWalletGUID = action.payload;

  const { smartWalletGUID: lastSmartWalletGUID } = yield select(
    (state) => state.smartWallet
  );

  if (
    typeof lastSmartWalletGUID !== "undefined" &&
    lastSmartWalletGUID !== smartWalletGUID
  ) {
    yield put(resetSmartWalletSection());
  }

  const { selectedDateRange } = yield select((state) => state.ui);

  yield* fetchSmartWalletSummary({
    smartWalletGUID,
    range: selectedDateRange,
  });
  yield* fetchSmartWalletRange(action);
}

export default fetchSmartWalletData;
