import { call, put } from "redux-saga/effects";
import { getLoadingState } from "app.utils/selectors";
import {
  requestLinkedExchanges,
  receiveLinkedExchangesError,
  receiveLinkedExchanges,
} from "app.actions/exchanges";
import ExchangesAPI from "app.api/ExchangesAPI";

import { applySourceOverrides } from "app.utils";

import { STATUS } from "app.constants";

function* fetchLinkedExchanges() {
  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.exchanges.linkedExchangesStatus
  );

  if (isLoading) return;

  yield put(requestLinkedExchanges(nextStatus));

  const response = yield call(ExchangesAPI.getLinkedExchanges);

  if (response.error) {
    yield put(receiveLinkedExchangesError(response.body, response.error));
  } else {
    const linkedExchanges = response.body.linked;

    const linkedExchangesOverridden = applySourceOverrides(linkedExchanges);

    const allLinkableExchangeAccounts = linkedExchangesOverridden
      .map((exchange) => {
        const formattedExchange = { ...exchange };
        delete formattedExchange.linked;

        return (exchange.linked || []).map((account) => ({
          ...account,
          exchange: formattedExchange,
        }));
      })
      .reduce((allExchangeAccounts, currentBatch) => {
        return [...allExchangeAccounts, ...currentBatch];
      }, []);

    yield put(
      receiveLinkedExchanges(
        { linked: linkedExchangesOverridden },
        allLinkableExchangeAccounts,
        STATUS.LOADED
      )
    );
  }
}

export default fetchLinkedExchanges;
