import { put, race, take } from "redux-saga/effects";
import {
  dismissModal,
  openModal,
  openApiKeyModal,
  completeApiKeyModal,
  dismissApiKeyModal,
  setModalActionCallback,
} from "app.reducers/ui";
import { MODAL_EXCHANGE_API_KEY_POP_UP } from "app.constants/modals";

function* showApiKeyModal() {
  while (true) {
    const action = yield take(openApiKeyModal);

    const { params, apiDismissCallback, apiLinkSuccessCallback } =
      action.payload;

    yield put(openModal(MODAL_EXCHANGE_API_KEY_POP_UP, undefined, params));

    const { dismiss, success } = yield race({
      dismiss: take([dismissApiKeyModal, dismissModal]),
      success: take(completeApiKeyModal),
    });

    if (dismiss) {
      if (typeof apiDismissCallback !== "undefined") {
        yield put(setModalActionCallback({ type: apiDismissCallback }));
      }
    }

    if (success) {
      const { linkGUID, autoImport } = success.payload;
      if (typeof apiLinkSuccessCallback !== "undefined") {
        const { exchange } = params;
        const apiLinkSuccessCallbackData = {
          type: apiLinkSuccessCallback,
          exchange,
          linkGUID,
          autoImport,
        };

        yield put(setModalActionCallback(apiLinkSuccessCallbackData));
      }
    }

    yield put(dismissModal());
  }
}

export default showApiKeyModal;
