import { call, put } from "redux-saga/effects";

import SmartWalletAPI from "app.api/SmartWalletAPI";

import * as smartWalletActions from "app.reducers/smartWallet";

import { getLoadingState } from "app.utils/selectors";

function* fetchSmartWalletSummary({ smartWalletGUID, range }) {
  const { isLoading } = yield* getLoadingState(
    (state) => state.smartWallet.smartWalletSummaryStatus
  );

  if (isLoading) return;

  yield put(smartWalletActions.requestSmartWalletSummary());

  // if the user leaves the section - this cancel action will fire
  // cancelling the handling the API response
  const response = yield call(
    SmartWalletAPI.getSmartWalletSummary,
    smartWalletGUID,
    typeof range?.startDate !== "undefined" ? range.startDate : undefined,
    typeof range?.endDate !== "undefined" ? range.endDate : undefined
  );

  // handle the API response
  if (response) {
    if (response.error) {
      console.error(
        "Error in fetchSmartWalletSummary",
        response.body,
        response.error
      );
    } else {
      yield put(
        smartWalletActions.receiveSmartWalletSummary({
          smartWalletSummary: response.body,
        })
      );
    }
  }
}

export default fetchSmartWalletSummary;
