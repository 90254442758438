/**
 * Updates the synchronization state of ledgers based on a provided sync list.
 * So that the UI can immediately reflect updated sync state before getting an
 * updated response from the server
 *
 * This function processes a list of synchronization details (`syncList`) and updates
 * the corresponding ledgers in the `ledgers` array by setting their `importInProgress`
 * property to `true` when a match is found.
 *
 * Matching logic:
 * 1. If `syncList[i].source` is defined, it matches ledgers by `exchangeId` and `linkGUID`.
 * 2. If `syncList[i].syncedLedgerIds` is defined, it matches ledgers by their `id`.
 *
 * The function returns a new array of ledgers with updated synchronization states.
 *
 * @param {Array} syncList - List of synchronization details.
 * @param {Array} ledgers - Array of ledgers to be updated.
 * @returns {Array} Updated array of ledgers with synchronization states applied.
 */

export function updateLedgerSyncState(syncList, ledgers) {
  const updatedLedgers = structuredClone(ledgers);
  for (let i = 0; i < syncList.length; i += 1) {
    if (typeof syncList[i].source !== "undefined") {
      const curSource = syncList[i].source;
      // set sync for these ledgers by ID
      for (let j = 0; j < updatedLedgers.length; j += 1) {
        if (
          updatedLedgers[j].exchangeId === curSource.id &&
          updatedLedgers[j].linked?.linkGUID === curSource.linkGUID
        ) {
          updatedLedgers[j].importInProgress = true;
        }
      }
    }
    if (typeof syncList[i].syncedLedgerIds !== "undefined") {
      const ledgerIds = syncList[i].syncedLedgerIds;
      // set sync for these ledgers by ID
      for (let j = 0; j < ledgerIds.length; j += 1) {
        const led = ledgers.find((l) => {
          return parseInt(l.id, 10) === parseInt(ledgerIds[j], 10);
        });

        if (led) led.importInProgress = true;
      }
    }
  }
  return updatedLedgers;
}
