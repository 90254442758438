import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { format, isAfter, formatISO, getQuarter } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { useGetUserTimeZone } from "app.hooks";
import { getElementPath } from "app.utils";
import { validateDate } from "app.utils/dates";
import { getRangePresets } from "app.components/Util/DatePicker";
import Select from "app.components/Util/Form/Select";

import { GENESIS_DATE } from "app.components/Util/DatePicker/rangePresets";
import SvgLoader from "app.components/Util/SvgLoader";
import Button from "app.components/Util/Button";
import DateInput from "app.components/Util/Form/DateInput";
import CalendarPicker from "./CalendarPicker";
import { getStartDate, getEndDate } from "./util";

const BUTTON_BASE_CLASS =
  "shadow flex border gap-1 rounded items-center bg-white px-2 py-1 border-zinc-100 disabled:opacity-50 whitespace-nowrap";
const FILTER_BASE_CLASS =
  "rounded shadow gap-1 px-3 py-1.5 uppercase text-sm text-left relative flex items-center disabled:opacity-50 whitespace-nowrap";

const DateRangePicker = ({
  selectedPreset,
  selectedRange,
  minSelectableDate = GENESIS_DATE,
  maxSelectableDate,
  selectRangeCallback,
  hideReset = false,
  minimumYear,
  disabled,
  filterStyleButton = false,
  enforceMaxVisible = false,
  maxSelectableDateOutOfBoundsError,
  hasLedgersNotUpdatedRecently,
}) => {
  const { t } = useTranslation();
  const userTimeZone = useGetUserTimeZone();
  const now = toZonedTime(new Date(), userTimeZone);

  const { THIS_MONTH, THIS_YEAR, ALL, YEARS, QUARTERS } = getRangePresets({
    now,
    minimumYear,
  });

  const [open, setOpen] = useState(false);

  const sd = getStartDate(selectedRange);
  const ed = getEndDate(selectedRange);

  const [visibleStartDate, setVisibleStartDate] = useState();
  const [visibleEndDate, setVisibleEndDate] = useState();

  const [showOutdatedDataWarning, setShowOutdatedDataWarning] = useState(false);

  const [yearValue, setYearValue] = useState("");

  const [qtrValue, setQtrValue] = useState("");
  const [qtrYearValue, setQtrYearValue] = useState("");

  const [qtrMode, setQtrMode] = useState(false);

  // when selected date changes, update visible date
  useEffect(() => {
    const sdt = getStartDate(selectedRange);
    const edt = getEndDate(selectedRange);

    setYearValue(selectedPreset === "YEAR" && sdt ? sdt.getFullYear() : "");
    setQtrYearValue(selectedPreset === "QTR" && sdt ? sdt.getFullYear() : "");
    setQtrValue(selectedPreset === "QTR" && sdt ? getQuarter(sdt) : "");
    setVisibleStartDate(selectedPreset === "CUSTOM" ? sdt : undefined);
    setVisibleEndDate(selectedPreset === "CUSTOM" ? edt : undefined);
  }, [
    selectedRange,
    selectedPreset,
    minSelectableDate,
    maxSelectableDate,
    setYearValue,
  ]);

  const container = useRef();
  const toggleButton = useRef();
  const selectYearButton = useRef();

  const selectedDate = visibleStartDate && visibleEndDate;

  // whenever the picker opens, set focus.
  useEffect(() => {
    if (open) {
      toggleButton.current.focus();
    }
  }, [open, toggleButton]);

  useEffect(() => {
    const handleClick = (e) => {
      if (e.target) if (!open) return;
      // normally, we check to see if the element is in the html
      // element path of the container to see if we should close
      // but here, we are ALSO checking to see if a particular button was clicked
      // because if that button was clicked, the component will be unmounted,
      // and it will appear as though the unmounted component is not in the path
      // so a bit of a hack, but we'll detect if this button was pressed by
      // inspecting the ID
      if (e.target.id === "select-year" || e.target.id === "select-year-cancel")
        return;
      const path = getElementPath(e.target);
      // if we clicked outside the container
      if (path.indexOf(container.current) === -1) {
        setOpen(false);
        setQtrMode(false);
      }
    };

    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        setOpen(false);
        setQtrMode(false);
      }
    };

    window.addEventListener("click", handleClick, false);
    window.addEventListener("keydown", handleKeyDown, false);
    return () => {
      window.removeEventListener("click", handleClick, false);
      window.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [open, setOpen, setQtrMode]);

  const selectRange = (range) => {
    const { startDate } = range.range;
    if (!isAfter(startDate, maxSelectableDate)) {
      setVisibleStartDate(undefined);
      setVisibleEndDate(undefined);
      selectRangeCallback(range.range, range.id);
      setOpen(false);
      setQtrMode(false);
      setShowOutdatedDataWarning(false);
      toggleButton.current.focus();
    } else {
      setShowOutdatedDataWarning(true);
    }
  };

  const toggleOpen = () => {
    setOpen(!open);
    setQtrMode(false);
  };

  const setCustomRange = () => {
    if (
      typeof visibleStartDate === "undefined" ||
      typeof visibleEndDate === "undefined"
    ) {
      return;
    }

    const range = {
      startDate: formatISO(visibleStartDate, { representation: "date" }),
      endDate: formatISO(visibleEndDate, { representation: "date" }),
    };
    selectRangeCallback(range, "CUSTOM");
    toggleButton.current.focus();
    setOpen(!open);
  };

  const selectYearRange = (year) => {
    // make a range
    const range = { startDate: `${year}-01-01`, endDate: `${year}-12-31` };
    setVisibleStartDate(undefined);
    setVisibleEndDate(undefined);
    toggleOpen(false);
    selectRangeCallback(range, "YEAR");
    toggleButton.current.focus();
  };

  const selectQtrRange = (qtr, year) => {
    const sq = QUARTERS.find((q) => q.id === qtr);

    const range = {
      startDate: `${year}-${sq.sd}`,
      endDate: `${year}-${sq.ed}`,
    };

    setVisibleStartDate(undefined);
    setVisibleEndDate(undefined);
    toggleOpen(false);
    selectRangeCallback(range, "QTR");
    toggleButton.current.focus();
  };

  const getDateRangeError = (
    isVisibleStartDayFuture,
    isVisibleStartDayPast,
    isVisibleEndDayFuture,
    isVisibleEndDayPast,
    isOrderValid,
    outdatedDataWarning
  ) => {
    if (!isOrderValid) {
      return (
        <p className="text-xs text-red-700">
          {t("utils.datePicker.startDateMustBeBeforeEndDate")}
        </p>
      );
    }

    if (isVisibleStartDayFuture || outdatedDataWarning) {
      return (
        <p className="text-xs text-red-700">
          {t("utils.datePicker.startDateFuture")}
        </p>
      );
    }

    if (isVisibleStartDayPast) {
      return (
        <p className="text-xs text-red-700">
          {t("utils.datePicker.startDatePast")}
        </p>
      );
    }

    if (isVisibleEndDayFuture) {
      if (maxSelectableDateOutOfBoundsError) {
        return (
          <p className="text-xs text-red-700">
            {t(maxSelectableDateOutOfBoundsError)}
          </p>
        );
      }
      return (
        <p className="text-xs text-red-700">
          {t("utils.datePicker.endDateFuture")}
        </p>
      );
    }
    if (isVisibleEndDayPast) {
      return (
        <p className="text-xs text-red-700">
          {t("utils.datePicker.endDatePast")}
        </p>
      );
    }

    return null;
  };

  const {
    isDateValid: isVisibleStartDateValid,
    isDayValid: isVisibleStartDayValid,
    isDateFuture: isVisibleStartDayFuture,
    isDatePast: isVisibleStartDayPast,
  } = validateDate(
    visibleStartDate,
    minSelectableDate,
    maxSelectableDate || now
  );

  const {
    isDateValid: isVisibleEndDateValid,
    isDayValid: isVisibleEndDayValid,
    isDateFuture: isVisibleEndDayFuture,
    isDatePast: isVisibleEndDayPast,
  } = validateDate(visibleEndDate, minSelectableDate, maxSelectableDate || now);

  const isOrderValid = !isAfter(visibleStartDate, visibleEndDate);

  const dateRangePickerBodyClassName = [
    "c_date-range-picker",
    open ? "opacity-1 scale-x-1 scale-y-1" : "opacity-0 scale-x-1 scale-y-0",
    "absolute",
    "shadow-menu",
    "rounded",
    "py-2",
    "px-2",
    "transition-[transform,opacity]",
    "origin-top",
    "bg-white",
    "overflow-hidden",
    "z-[30]",
    "ml-0",
    "mt-1",
    "w-[32rem]",
  ]
    .filter(Boolean)
    .join(" ");

  const buttonClass = [
    filterStyleButton ? FILTER_BASE_CLASS : BUTTON_BASE_CLASS,
    filterStyleButton && (open || (!open && selectedPreset !== ALL.id))
      ? "bg-blue-600 text-white"
      : "",
    filterStyleButton && !open && selectedPreset === ALL.id
      ? "bg-white text-blue-400"
      : "",
  ]
    .filter(Boolean)
    .join(" ");

  const dateRangeError = getDateRangeError(
    isVisibleStartDayFuture,
    isVisibleStartDayPast,
    isVisibleEndDayFuture,
    isVisibleEndDayPast,
    isOrderValid,
    showOutdatedDataWarning
  );

  return (
    <div ref={container}>
      <div className="flex items-center">
        <button
          ref={toggleButton}
          className={buttonClass}
          data-testid="open-button"
          type="button"
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
            toggleOpen();
          }}
        >
          <SvgLoader
            id="Calendar"
            className={
              filterStyleButton && (open || selectedPreset !== ALL.id)
                ? "h-5 w-5 fill-white"
                : "h-5 w-5 fill-blue-600"
            }
          />
          <div className="text-sm" data-testid="selected-preset">
            {selectedPreset === ALL.id ? (
              t("utils.datePicker.allDates")
            ) : (
              <div>
                {(sd && format(sd, "MMMM d, yyyy")) ||
                  t("utils.datePicker.startDate")}
                &nbsp;&rarr;&nbsp;
                {typeof ed !== "undefined"
                  ? format(ed, "MMMM d, yyyy")
                  : t("utils.datePicker.endDate")}
              </div>
            )}
          </div>
          {filterStyleButton ? (
            <SvgLoader
              id="Chevron"
              className={
                open
                  ? "h-1.5 h-3.5 w-3.5 rotate-90 stroke-white"
                  : "h-1.5 h-3.5 w-3.5 rotate-90 stroke-blue-400"
              }
            />
          ) : (
            <div
              className={[
                "caret",
                selectedDate ? "border-t-blue-600" : "border-t-zinc-600",
              ].join(" ")}
            />
          )}
        </button>
        {hideReset !== true && selectedPreset !== ALL.id ? (
          <Button
            text={t("common.resetFilter")}
            onClick={(e) => {
              e.preventDefault();
              selectRange(ALL);
            }}
            buttonSize="sm"
            buttonType="text"
            customClasses="ml-2"
          />
        ) : null}
      </div>
      <div
        data-testid="dropdown-content"
        id="dropdown-content"
        aria-hidden={!open}
        className={dateRangePickerBodyClassName}
      >
        <div>
          {hasLedgersNotUpdatedRecently ? (
            <div className="mb-2 flex items-center gap-1 rounded bg-amber-50 px-2 py-1">
              <SvgLoader
                id="Warning"
                className="h-5 w-5 min-w-4 fill-amber-400"
              />
              <div className="text-sm text-amber-800">
                {t("portfolio.youMayNeedToSyncLedgers")}
              </div>
            </div>
          ) : null}
          <div className="flex" ref={selectYearButton}>
            <Button
              disabled={!open}
              text={t("utils.datePicker.allDates")}
              buttonSize="sm"
              buttonType={selectedPreset === ALL.id ? "primary" : "quickPick"}
              customClasses="mr-2"
              onClick={(e) => {
                e.preventDefault();
                selectRange(ALL);
              }}
            />
            <Button
              text={t("button.thisMonth")}
              buttonSize="sm"
              onClick={(e) => {
                e.preventDefault();
                selectRange(THIS_MONTH);
              }}
              disabled={!open}
              customClasses="mr-2"
              buttonType={
                selectedPreset === THIS_MONTH.id ? "primary" : "quickPick"
              }
            />
            <Button
              disabled={!open}
              buttonSize="sm"
              text={t("button.thisYear")}
              onClick={(e) => {
                e.preventDefault();
                selectRange(THIS_YEAR);
              }}
              buttonType={
                selectedPreset === THIS_YEAR.id ? "primary" : "quickPick"
              }
              customClasses="mr-2"
            />
            <Button
              disabled={!open}
              buttonSize="sm"
              text={t("utils.datePicker.qtr")}
              onClick={(e) => {
                e.preventDefault();
                setQtrMode(true);
              }}
              buttonType={selectedPreset === "QTR" ? "primary" : "quickPick"}
              customClasses="mr-2"
            />

            <Select
              disabled={!open}
              value={yearValue}
              onChange={(e) => {
                e.preventDefault();
                if (e.target.value) {
                  selectYearRange(e.target.value);
                }
              }}
              buttonType="custom"
              buttonSize="xs"
              className={
                selectedPreset === "YEAR"
                  ? "bg-blue-605 border-none bg-blue-600 px-2.5 py-1 uppercase text-white shadow-none"
                  : "border-none bg-zinc-100 px-2.5 py-1 uppercase text-blue-600 shadow-none hover:bg-zinc-200"
              }
            >
              <option value="">
                {t("utils.datePicker.selectAYear_withEllipsis")}
              </option>
              {YEARS.map((y) => {
                return (
                  <option value={y} key={y}>
                    {y}
                  </option>
                );
              })}
            </Select>
          </div>

          {qtrMode ? (
            <div className="absolute left-2 right-2 top-0 rounded bg-white py-2">
              <div className="flex">
                {QUARTERS.map((q) => {
                  return (
                    <Button
                      disabled={!open}
                      buttonSize="sm"
                      key={q.id}
                      text={q.label}
                      onClick={(e) => {
                        e.preventDefault();
                        setQtrValue(q.id);
                      }}
                      buttonType={qtrValue === q.id ? "primary" : "quickPick"}
                      customClasses="mr-2"
                    />
                  );
                })}
                <Select
                  disabled={!open}
                  buttonSize="sm"
                  buttonType="custom"
                  value={qtrYearValue}
                  onChange={(e) => {
                    e.preventDefault();
                    setQtrYearValue(e.target.value);
                  }}
                  className="mr-2 border-none bg-zinc-100 px-2.5 text-xs uppercase text-blue-600 shadow-none hover:bg-zinc-200"
                >
                  <option value="">
                    {t("utils.datePicker.selectAYear_withEllipsis")}
                  </option>
                  {YEARS.map((y) => {
                    return (
                      <option value={y} key={y}>
                        {y}
                      </option>
                    );
                  })}
                </Select>
                <Button
                  disabled={qtrValue === "" || qtrYearValue === ""}
                  buttonType="primary"
                  buttonSize="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    selectQtrRange(qtrValue, qtrYearValue);
                  }}
                >
                  {t("button.setRange")}
                </Button>
                <button
                  disabled={!open}
                  type="button"
                  className="ml-auto"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setQtrMode(false);
                  }}
                  title={t("button.close")}
                >
                  <SvgLoader
                    id="Close"
                    className="h-4 w-4 stroke-zinc-400 hover:stroke-blue-600"
                  />
                </button>
              </div>
            </div>
          ) : null}
          <div className="mb-2 mt-4 text-sm">
            <span className="font-bold uppercase text-gray-400">
              {t("utils.datePicker.customDateRange")}
            </span>
          </div>
          <div className="flex items-start justify-between">
            <CalendarPicker
              open={open}
              isStart
              selectedPreset={selectedPreset}
              selectedDate={
                isVisibleStartDateValid ? visibleStartDate : undefined
              }
              setSelectedDate={setVisibleStartDate}
              otherDate={visibleEndDate}
              blockFutureDates
              minSelectableDate={minSelectableDate}
              maxSelectableDate={maxSelectableDate}
              enforceMaxVisible={enforceMaxVisible}
            />
            <CalendarPicker
              open={open}
              isEnd
              selectedPreset={selectedPreset}
              selectedDate={isVisibleEndDateValid ? visibleEndDate : undefined}
              setSelectedDate={setVisibleEndDate}
              otherDate={visibleStartDate}
              blockFutureDates
              minSelectableDate={minSelectableDate}
              maxSelectableDate={maxSelectableDate}
              enforceMaxVisible={enforceMaxVisible}
            />
          </div>
          <div className="h-4">{dateRangeError}</div>
          <div className="mt-2 flex justify-between">
            <div className="flex items-center">
              <DateInput
                disabled={!open}
                open={open}
                selectedDate={visibleStartDate}
                invalidDay={visibleStartDate && !isVisibleStartDayValid}
                invalidDate={visibleStartDate && !isVisibleStartDateValid}
                placeholder={t("utils.datePicker.startDate")}
                selectCallback={setVisibleStartDate}
                focus={false}
              />
              <div className="mx-1">&nbsp;&rarr;&nbsp;</div>
              <DateInput
                open={open}
                disabled={!open}
                selectedDate={visibleEndDate}
                invalidDay={visibleEndDate && !isVisibleEndDayValid}
                invalidDate={visibleEndDate && !isVisibleEndDateValid}
                placeholder={t("utils.datePicker.endDate")}
                selectCallback={setVisibleEndDate}
                focus={false}
              />
            </div>

            <div className="flex items-center">
              {selectedPreset !== ALL.id ? (
                <Button
                  disabled={!open}
                  onClick={(e) => {
                    e.preventDefault();
                    selectRange(ALL);
                  }}
                  text={t("common.resetFilter")}
                  buttonType="text"
                  buttonSize="sm"
                  customClasses="mr-2"
                />
              ) : (
                <div />
              )}
              <Button
                data-testid="select-range"
                buttonSize="sm"
                buttonType="primary"
                onClick={(e) => {
                  e.preventDefault();
                  setCustomRange();
                }}
                disabled={
                  !open ||
                  !isOrderValid ||
                  !isVisibleStartDateValid ||
                  !isVisibleEndDateValid ||
                  typeof visibleStartDate === "undefined" ||
                  typeof visibleEndDate === "undefined"
                }
                text={t("button.setRange")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DateRangePicker.displayName = "DateRangePicker";

export default DateRangePicker;
