import React, { useState, useEffect, useRef } from "react";

import { ONE_SECOND } from "app.constants";
import SvgLoader from "app.components/Util/SvgLoader";
import { useTranslation } from "react-i18next";

const CopyToClipboard = ({ text, label, shownLabel = "", disabled }) => {
  const [copyState, setCopyState] = useState(false);
  const [copyError, setCopyError] = useState(false);
  const isMountedRef = useRef(false);
  const { t } = useTranslation();

  function copyToClipboard(txt) {
    navigator.clipboard.writeText(txt).then(
      () => {
        setCopyState(true);
        setTimeout(() => {
          if (isMountedRef.current) {
            setCopyState(false);
          }
        }, ONE_SECOND * 2);
      },
      () => {
        setCopyError(true);
        setTimeout(() => {
          if (isMountedRef.current) {
            setCopyError(false);
          }
        }, ONE_SECOND * 2);
      }
    );
  }

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return (
    <button
      type="button"
      disabled={disabled}
      title={
        label
          ? t("clipboard.copyToClipboardwithLabel", { label })
          : t("clipboard.copyToClipboard")
      }
      onClick={(e) => {
        e.preventDefault();
        copyToClipboard(text);
      }}
      className="group/copy relative flex items-center gap-1 px-0 py-0"
    >
      {copyState ? (
        <span className="absolute right-0 top-full rounded bg-black px-1 py-1 text-xs normal-case leading-tight text-white shadow">
          {t("clipboard.copied")}
        </span>
      ) : null}
      {copyError ? (
        <span className="absolute right-0 top-full rounded bg-red-800 px-1 py-1 text-xs normal-case leading-tight text-white shadow">
          {t("clipboard.copyError")}
        </span>
      ) : null}
      <SvgLoader
        id="DocumentDuplicate"
        className="h-4 w-4 stroke-zinc-500 transition-all group-hover/copy:stroke-blue-600"
      />
      {shownLabel && shownLabel}
    </button>
  );
};

export default CopyToClipboard;
