import { takeEvery, takeLeading } from "redux-saga/effects";

import * as smartWalletActions from "app.reducers/smartWallet";

import disableSmartWallet from "./disableSmartWallet";
import enableSmartWallet from "./enableSmartWallet";
import fetchSmartWalletData from "./fetchSmartWalletData";
import fetchSmartWalletList from "./fetchSmartWalletList";
import pollSmartWalletList from "./pollSmartWalletList";
import fetchSmartWalletSummary from "./fetchSmartWalletSummary";

const smartWalletSagas = [
  takeEvery(smartWalletActions.enableSmartWallet, enableSmartWallet),
  takeEvery(smartWalletActions.disableSmartWallet, disableSmartWallet),
  takeEvery(smartWalletActions.fetchSmartWalletList, fetchSmartWalletList),
  takeLeading(smartWalletActions.pollSmartWalletList, pollSmartWalletList),

  takeLeading(smartWalletActions.fetchSmartWalletData, fetchSmartWalletData),

  takeLeading(
    smartWalletActions.fetchSmartWalletSummary,
    fetchSmartWalletSummary
  ),
];

export default smartWalletSagas;
