import { call, put, select, all } from "redux-saga/effects";

import getBasicLocalStorageConfig from "app.sagas/ui/getBasicLocalStorageConfig";
import getBasicSessionStorageConfig from "app.sagas/ui/getBasicSessionStorageConfig";

import {
  requestAuthenticationStatus,
  receiveAuthenticationStatus,
  goToLogin,
  autoRefreshOrganizationToken,
  autoRefreshUserToken,
  refreshUserToken,
} from "app.actions/authentication";

import {
  resetOrganization,
  receiveUserOrganization,
  organizationSectionLoaded,
  unassumeProfile,
  receiveAuthorizeOrganization,
} from "app.reducers/organizations";
import { doRedirect } from "app.reducers/ui";
import {
  resetUser,
  receiveAuthorizeUser,
  receiveUserSubscription,
} from "app.reducers/user";

import { canManageOrganizationProfiles } from "app.utils/selectors";
import { getSortBy } from "app.utils";

import { STATUS, TIERS } from "app.constants";

import AuthAPI from "app.api/AuthAPI";
import SubscriptionAPI from "app.api/SubscriptionAPI";
import OrganizationAPI from "app.api/OrganizationAPI";

function* checkAuthenticationStatus() {
  const state = yield select((s) => s);
  const orgState = state.organizations;
  const userState = state.user;

  const { organizationAuthToken, currentlyAssumedProfile } = orgState;
  const { token } = userState;
  let userToken = token;

  let authenticatedAsOrg = false;
  let authenticatedAsUser = false;

  let hasOrgToken = typeof organizationAuthToken !== "undefined";
  let hasUserToken = typeof userToken !== "undefined";
  let firstTime = false;

  const redirectTo = window.location.pathname + window.location.search;

  if (!hasOrgToken && !hasUserToken) {
    firstTime = true;
    // we are probably never going to have tokens, so
    // lets get a ticket from balance
    try {
      const ticket = yield call(AuthAPI.getTicket);
      let response;
      let assumedResponse;
      if (currentlyAssumedProfile) {
        [response, assumedResponse] = yield all([
          call(AuthAPI.getTokenFromTicket, ticket),
          call(AuthAPI.getTokenFromTicket, ticket, currentlyAssumedProfile),
        ]);
      } else {
        response = yield call(AuthAPI.getTokenFromTicket, ticket);
      }

      const { body } = response;
      const { organizationAccessLevel, access_token: accessToken } = body;

      // Do not put these in a yield all call; we have to consume the receive auth call
      // before we can navigate or we crash the app. so there's that
      if (
        organizationAccessLevel &&
        canManageOrganizationProfiles(organizationAccessLevel)
      ) {
        yield put(receiveAuthorizeOrganization(accessToken, body));
        hasOrgToken = true;
        if (currentlyAssumedProfile && assumedResponse) {
          const { body: b } = assumedResponse;
          const { access_token: assumedToken } = b;
          yield put(receiveAuthorizeUser(assumedToken));
          userToken = assumedToken;
          hasUserToken = true;
        }
      } else {
        yield put(receiveAuthorizeUser(accessToken));
        userToken = accessToken;
        hasUserToken = true;
      }
    } catch (e) {
      if (e.error === 401) {
        yield put(goToLogin(redirectTo));
      } else if (e && Object.keys(e).length) {
        console.error({ ...e });
      }
    }
  }

  yield put(requestAuthenticationStatus());

  // We perform a different error action for normal user profile
  // checking depending on whether or not we have a valid org token

  if (hasOrgToken) {
    // We have an auth token; we assume it's valid
    // If not, our handles on Terminus will auto-log out for us.
    const fetchOrgResponse = yield call(OrganizationAPI.getOrganizationDetails);

    if (fetchOrgResponse.error) {
      // Org token is bad; exit out, go to login
      console.error(
        "Error in fetchOrganization",
        fetchOrgResponse.body,
        fetchOrgResponse.error
      );
      // reset org, we could not login as one
      yield put(resetOrganization());
    } else {
      authenticatedAsOrg = true;

      const results = fetchOrgResponse.body;
      results.profiles.sort(
        getSortBy((x) => x.profileDisplayName.toLowerCase(), true)
      );

      yield all([
        put(receiveUserOrganization(results)),
        put(organizationSectionLoaded()),
        put(autoRefreshOrganizationToken()),
      ]);
    }
  }

  if (typeof userToken !== "undefined") {
    const response = yield call(SubscriptionAPI.getUserSubscription);

    if (response.error) {
      // We're still actually an org admin, and we just timed out the old user JWT
      if (authenticatedAsOrg) {
        yield put(unassumeProfile());
        yield put(doRedirect("/organization"));
      } else {
        yield put(resetUser());
      }
    } else if (response.body) {
      // get the key
      const allUserSubscriptions = Object.keys(response.body);
      const values = TIERS.values();
      const userTiers = values.filter(
        (x) => allUserSubscriptions.indexOf(x.id) >= 0
      );

      const highestTier = userTiers.length
        ? userTiers.reduce((currentMaxTier, currentTier) => {
            if (!currentMaxTier) return currentTier;
            if (currentTier.ordinal > currentMaxTier.ordinal)
              return currentTier;
            return currentMaxTier;
          })
        : {};

      const formattedResponse = {
        ...response.body[highestTier.id],
        tier: highestTier.ordinal,
      };

      authenticatedAsUser = true;

      yield all([
        put(autoRefreshUserToken()),
        put(
          receiveUserSubscription({
            response: formattedResponse,
            status: STATUS.LOADED,
          })
        ),
      ]);
    }
  }

  if (!(authenticatedAsUser || authenticatedAsOrg)) {
    yield put(goToLogin(redirectTo));
    return;
  }

  yield put(receiveAuthenticationStatus());
  // if we just logged in, we won't need to refresh the token now
  // we may need to if something happened that affects a users account/permissions
  if (!firstTime && (hasUserToken || currentlyAssumedProfile)) {
    yield put(refreshUserToken());
  }
  if (currentlyAssumedProfile) {
    yield put(autoRefreshUserToken());
  }

  yield* getBasicLocalStorageConfig();
  yield* getBasicSessionStorageConfig({ hasOrgToken });
}

export default checkAuthenticationStatus;
