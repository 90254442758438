/* eslint-disable no-param-reassign */
// this rule is disabled in reducers because the redux toolkit
// explicitly supports direct mutation of the state param
// https://redux-toolkit.js.org/usage/immer-reducers
import { createSlice } from "@reduxjs/toolkit";

// import * as organizationActions from "app.reducers/organizations";
import { receiveAuthorizeUser } from "app.reducers/user";
import * as authenticationActions from "app.actions/authentication";
import { dismissModal } from "app.reducers/ui";

import {
  testSessionStorage,
  persistToSession,
  retrieveFromSession,
} from "app.utils";

import {
  SESSION_STORAGE_KEY_ORGS,
  SESSION_COPY_URL_ID,
  STATUS,
} from "app.constants";

import { jwtDecode } from "jwt-decode";

const keysToPersist = ["currentlyAssumedProfile"];

// each reducer needs its own local storage key
const getInitialState = () => {
  const baseState = {
    organizationAuthToken: undefined,
    organizationUser: undefined,
    organizationLoadingState: STATUS.UNINITIALIZED,
    organization: undefined,
    currentlyAssumedProfile: undefined,
    assumeProfileStatus: STATUS.UNINITIALIZED,
    upsertProfileError: undefined,
    upsertProfileStatus: STATUS.UNINITIALIZED,
    deleteProfileError: undefined,
    deleteProfileLoading: false,
    deleteProfileStatus: STATUS.UNINITIALIZED,
    deleteTeamMemberLoading: false,
    deleteTeamMemberError: undefined,
    undoDeleteProfileError: undefined,
    undoDeleteProfileLoading: false,
    undoDeleteProfileStatus: STATUS.UNINITIALIZED,
    teamMemberList: undefined,
    teamMemberStatus: STATUS.UNINITIALIZED,
    upsertTeamMemberStatus: STATUS.UNINITIALIZED,
    upsertTeamMemberError: undefined,
    reinvitedProfiles: {},
    reinvitedError: undefined,
    preferences: {},
    preferencesStatus: STATUS.UNINITIALIZED,
  };

  const sessionStorageTestResult = testSessionStorage();

  if (sessionStorageTestResult) {
    // enables transfer of session (assumed profile) when opening in a new tab
    // if the URL provides a session ID
    const params = new URLSearchParams(window.location.search);
    if (params.get(SESSION_COPY_URL_ID)) {
      persistToSession(
        { currentlyAssumedProfile: params.get(SESSION_COPY_URL_ID) },
        keysToPersist,
        SESSION_STORAGE_KEY_ORGS
      );
    }
  }
  return retrieveFromSession(baseState, SESSION_STORAGE_KEY_ORGS);
};

export const organizationsSlice = createSlice({
  name: "organizations",
  initialState: getInitialState(),
  reducers: {
    enterSettingsSection: () => {},
    enterTeamMemberSection: () => {},
    resetTeamMemberSection: () => {},
    receiveAuthorizeOrganization: {
      reducer: (state, action) => {
        state.organizationAuthToken = action.payload.token;
        state.organizationUser = jwtDecode(action.payload.token);
        persistToSession(state, keysToPersist, SESSION_STORAGE_KEY_ORGS);
      },
      prepare(token) {
        return { payload: { token } };
      },
    },
    organizationSectionEnter: (state) => {
      state.organizationLoadingState = STATUS.LOADING;
    },
    organizationSectionLoaded: (state) => {
      state.organizationLoadingState = STATUS.LOADED;
    },
    resetOrganization: (state) => {
      state.organizationAuthToken = undefined;
      state.organizationUser = undefined;
    },
    // fetch org
    fetchOrganization: () => {},
    receiveUserOrganization: {
      reducer: (state, action) => {
        state.organization = action.payload.organization;
        persistToSession(state, keysToPersist, SESSION_STORAGE_KEY_ORGS);
      },
      prepare(organization) {
        return { payload: { organization } };
      },
    },
    // assume profile
    assumeProfile: {
      reducer: (state, action) => {
        state.currentlyAssumedProfile = action.payload.profileGUID;
        persistToSession(state, keysToPersist, SESSION_STORAGE_KEY_ORGS);
      },
      prepare(profileGUID) {
        return { payload: { profileGUID } };
      },
    },
    assumeProfileRequest: {
      reducer: (state, action) => {
        state.currentlyAssumedProfile = undefined;
        state.assumeProfileStatus = action.payload.nextStatus;
        persistToSession(state, keysToPersist, SESSION_STORAGE_KEY_ORGS);
      },
      prepare(nextStatus) {
        return { payload: { nextStatus } };
      },
    },
    assumeProfileReceive: {
      reducer: (state, action) => {
        state.currentlyAssumedProfile = action.payload.profileGUID;
        state.assumeProfileStatus = action.payload.nextStatus;
        persistToSession(state, keysToPersist, SESSION_STORAGE_KEY_ORGS);
      },
      prepare(profileGUID, nextStatus) {
        return { payload: { profileGUID, nextStatus } };
      },
    },
    // unassume profile
    unassumeProfile: () => {},
    unassumeProfileRequest: {
      reducer: (state, action) => {
        state.currentlyAssumedProfile = undefined;
        state.assumeProfileStatus = action.payload.nextStatus;
        persistToSession(state, keysToPersist, SESSION_STORAGE_KEY_ORGS);
      },
      prepare(nextStatus) {
        return { payload: { nextStatus } };
      },
    },
    unassumeProfileReceive: {
      reducer: (state, action) => {
        state.currentlyAssumedProfile = undefined;
        state.assumeProfileStatus = action.payload.nextStatus;
        persistToSession(state, keysToPersist, SESSION_STORAGE_KEY_ORGS);
      },
      prepare(nextStatus) {
        return { payload: { nextStatus } };
      },
    },
    // profile add
    addProfile: {
      reducer: () => {},
      prepare(
        assumeOnCreation,
        displayName,
        associatedEmailAddress,
        firstName,
        lastName
      ) {
        return {
          payload: {
            assumeOnCreation,
            displayName,
            associatedEmailAddress,
            firstName,
            lastName,
          },
        };
      },
    },
    addProfileRequest: {
      reducer: (state, action) => {
        state.upsertProfileError = undefined;
        state.upsertProfileStatus = action.payload.nextStatus;
      },
      prepare(nextStatus) {
        return { payload: { nextStatus } };
      },
    },
    addProfileReceive: {
      reducer: (state, action) => {
        state.upsertProfileError = undefined;
        state.upsertProfileStatus = action.payload.nextStatus;
      },
      prepare(profile, nextStatus) {
        return { payload: { profile, nextStatus } };
      },
    },
    addProfileError: {
      reducer: (state, action) => {
        state.upsertProfileError = action.payload.error;
        state.upsertProfileStatus = action.payload.nextStatus;
      },
      prepare(error, nextStatus) {
        return { payload: { error, nextStatus } };
      },
    },

    // profile updated
    updateProfile: {
      reducer: () => {},
      prepare(
        profileGUID,
        displayName,
        userGUID,
        associatedEmailAddress,
        firstName,
        lastName,
        shouldInvite
      ) {
        return {
          payload: {
            profileGUID,
            displayName,
            userGUID,
            associatedEmailAddress,
            firstName,
            lastName,
            shouldInvite,
          },
        };
      },
    },
    updateProfileRequest: {
      reducer: (state, action) => {
        state.upsertProfileError = undefined;
        state.upsertProfileStatus = action.payload.nextStatus;
      },
      prepare(nextStatus) {
        return { payload: { nextStatus } };
      },
    },
    updateProfileReceive: {
      reducer: (state, action) => {
        state.upsertProfileError = undefined;
        state.upsertProfileStatus = action.payload.nextStatus;
      },
      prepare(profile, nextStatus) {
        return { payload: { profile, nextStatus } };
      },
    },
    updateProfileError: {
      reducer: (state, action) => {
        state.upsertProfileError = action.payload.error;
        state.upsertProfileStatus = action.payload.nextStatus;
      },
      prepare(error, nextStatus) {
        return { payload: { error, nextStatus } };
      },
    },
    deleteProfile: {
      reducer: () => {},
      prepare(profileGUID) {
        return { payload: { profileGUID } };
      },
    },
    deleteProfileRequest: (state) => {
      state.deleteProfileLoading = true;
      state.deleteProfileError = undefined;
    },
    deleteProfileReceive: (state) => {
      state.deleteProfileLoading = false;
      state.deleteProfileError = undefined;
    },
    deleteProfileError: {
      reducer: (state, action) => {
        state.deleteProfileError = action.payload.error;
        state.deleteProfileLoading = false;
      },
      prepare(error) {
        return { payload: { error } };
      },
    },
    undoDeleteProfile: {
      reducer: () => {},
      prepare(profileGUID) {
        return { payload: { profileGUID } };
      },
    },

    deleteTeamMember: {
      reducer: () => {},
      prepare(teamMemberId) {
        return { payload: { teamMemberId } };
      },
    },
    deleteTeamMemberRequest: (state) => {
      state.deleteTeamMemberLoading = true;
      state.deleteTeamMemberError = undefined;
    },
    deleteTeamMemberReceive: (state) => {
      state.deleteTeamMemberLoading = false;
      state.deleteTeamMemberError = undefined;
    },
    deleteTeamMemberError: {
      reducer: (state, action) => {
        state.deleteTeamMemberError = action.payload.error;
        state.deleteTeamMemberLoading = false;
      },
      prepare(error) {
        return { payload: { error } };
      },
    },

    undoDeleteProfileRequest: (state) => {
      state.undoDeleteProfileLoading = true;
      state.undoDeleteProfileError = undefined;
    },
    undoDeleteProfileReceive: (state) => {
      state.undoDeleteProfileLoading = false;
      state.undoDeleteProfileError = undefined;
    },
    undoDeleteProfileError: {
      reducer: (state, action) => {
        state.undoDeleteProfileError = action.payload.error;
        state.undoDeleteProfileLoading = false;
      },
      prepare(error) {
        return { payload: { error } };
      },
    },
    // team member list
    fetchTeamMemberList: () => {},
    requestTeamMemberList: {
      reducer: (state, action) => {
        state.teamMemberStatus = action.payload.nextStatus;
      },
      prepare(nextStatus) {
        return { payload: { nextStatus } };
      },
    },
    receiveTeamMemberList: {
      reducer: (state, action) => {
        state.teamMemberList = action.payload.teamMemberList;
        state.teamMemberStatus = action.payload.nextStatus;
      },
      prepare(teamMemberList, nextStatus) {
        return { payload: { teamMemberList, nextStatus } };
      },
    },
    receiveTeamMemberListError: {
      reducer: (state, action) => {
        state.teamMemberStatus = action.payload.nextStatus;
      },
      prepare(error, nextStatus) {
        return { payload: { error, nextStatus } };
      },
    },
    updateTeamMemberAccess: {
      reducer: () => {},
      prepare(id, admin) {
        return { payload: { id, admin } };
      },
    },
    updateTeamMemberAccessRequest: {
      reducer: (state, action) => {
        state.upsertTeamMemberError = undefined;
        state.upsertTeamMemberStatus = action.payload.nextStatus;
      },
      prepare(nextStatus) {
        return { payload: { nextStatus } };
      },
    },
    updateTeamMemberAccessReceive: {
      reducer: (state, action) => {
        state.upsertTeamMemberError = undefined;
        state.upsertTeamMemberStatus = action.payload.nextStatus;
      },
      prepare(nextStatus) {
        return { payload: { nextStatus } };
      },
    },
    updateTeamMemberAccessError: {
      reducer: (state, action) => {
        state.upsertTeamMemberError = action.payload.error;
        state.upsertTeamMemberStatus = action.payload.nextStatus;
      },
      prepare(error, nextStatus) {
        return { payload: { error, nextStatus } };
      },
    },
    addTeamMember: {
      reducer: () => {},
      prepare(email, firstName, lastName, admin) {
        return { payload: { email, firstName, lastName, admin } };
      },
    },
    addTeamMemberRequest: {
      reducer: (state, action) => {
        state.upsertTeamMemberError = action.payload.error;
        state.upsertTeamMemberStatus = action.payload.nextStatus;
      },
      prepare(error, nextStatus) {
        return { payload: { error, nextStatus } };
      },
    },
    addTeamMemberAccessReceive: (state) => {
      state.upsertTeamMemberError = undefined;
    },
    addTeamMemberReceive: {
      reducer: (state, action) => {
        state.upsertTeamMemberError = undefined;
        state.upsertTeamMemberStatus = action.payload.nextStatus;
      },
      prepare(nextStatus) {
        return { payload: { nextStatus } };
      },
    },
    addTeamMemberError: {
      reducer: (state, action) => {
        state.upsertTeamMemberError = action.payload.error;
      },
      prepare(error) {
        return { payload: { error } };
      },
    },
    addTeamMemberAccessError: {
      reducer: (state, action) => {
        state.upsertTeamMemberError = action.payload.error;
      },
      prepare(error) {
        return { payload: { error } };
      },
    },
    enterTeamMemberEditor: (state) => {
      state.upsertTeamMemberError = undefined;
      state.upsertTeamMemberStatus = STATUS.UNINITIALIZED;
    },
    exitTeamMemberEditor: (state) => {
      state.upsertTeamMemberError = undefined;
      state.upsertTeamMemberStatus = STATUS.UNINITIALIZED;
    },
    exitProfileEditor: (state) => {
      state.upsertProfileError = undefined;
      state.upsertProfileStatus = STATUS.UNINITIALIZED;
    },
    reinviteTeamMember: {
      reducer: () => {},
      prepare(profileGUID, email) {
        return { payload: { profileGUID, email } };
      },
    },
    reinviteTeamMemberRequest: {
      reducer: (state, action) => {
        state.reinvitedProfiles = {
          ...state.reinvitedProfiles,
          [action.payload.profileGUID]: STATUS.LOADING,
        };
        state.reinvitedError = undefined;
      },
      prepare(profileGUID) {
        return { payload: { profileGUID } };
      },
    },
    reinviteTeamMemberReceive: {
      reducer: (state, action) => {
        state.reinvitedProfiles = {
          ...state.reinvitedProfiles,
          [action.payload.profileGUID]: STATUS.SUCCESS,
        };
        state.reinvitedError = undefined;
      },
      prepare(profileGUID) {
        return { payload: { profileGUID } };
      },
    },
    reinviteTeamMemberError: {
      reducer: (state, action) => {
        state.reinvitedProfiles = {
          ...state.reinvitedProfiles,
          [action.payload.profileGUID]: STATUS.ERROR,
        };
        state.reinvitedError = action.payload.error;
      },
      prepare(profileGUID, error) {
        return { payload: { profileGUID, error } };
      },
    },
    inviteUser: {
      reducer: () => {},
      prepare(userGUID, associatedEmailAddress, firstName, lastName) {
        return {
          payload: { userGUID, associatedEmailAddress, firstName, lastName },
        };
      },
    },
    inviteUserRequest: () => {},
    inviteUserReceive: {
      reducer: () => {},
      prepare(result) {
        return { payload: { result } };
      },
    },
    inviteUserError: {
      reducer: () => {},
      prepare(error) {
        return { payload: { error } };
      },
    },
    reinviteUser: {
      reducer: () => {},
      prepare(userGUID, email) {
        return { payload: { userGUID, email } };
      },
    },
    reinviteUserRequest: {
      reducer: (state, action) => {
        state.reinvitedProfiles = {
          ...state.reinvitedProfiles,
          [action.payload.userGUID]: STATUS.LOADING,
        };
        state.reinvitedError = undefined;
      },
      prepare(userGUID) {
        return { payload: { userGUID } };
      },
    },
    reinviteUserReceive: {
      reducer: (state, action) => {
        state.reinvitedProfiles = {
          ...state.reinvitedProfiles,
          [action.payload.userGUID]: STATUS.SUCCESS,
        };
        state.reinvitedError = undefined;
      },
      prepare(userGUID) {
        return { payload: { userGUID } };
      },
    },
    reinviteUserError: {
      reducer: (state, action) => {
        state.reinvitedProfiles = {
          ...state.reinvitedProfiles,
          [action.payload.userGUID]: STATUS.ERROR,
        };
        state.reinvitedError = action.payload.error;
      },
      prepare(userGUID, error) {
        return { payload: { userGUID, error } };
      },
    },
    fetchOrgAccountingPreferences: () => {},
    requestOrgAccountingPreferences: (state) => {
      state.preferencesStatus =
        state.preferencesStatus === STATUS.UNINITIALIZED
          ? STATUS.LOADING
          : STATUS.RELOADING;
    },
    receiveOrgAccountingPreferences: {
      reducer: (state, action) => {
        state.preferencesStatus = STATUS.LOADED;
        state.preferences = action.payload.preferences;
      },
      prepare(preferences) {
        return { payload: { preferences } };
      },
    },
    orgAccountingPreferencesError: (state) => {
      state.preferencesStatus = STATUS.LOADED;
    },
    saveAccountingPreferences: () => {},
    requestSaveAccountingPreferences: () => {},
    receiveSaveAccountingPreferences: () => {},
    saveAccountingPreferencesError: () => {},
    saveOrgAccountingPreferences: () => {},
    requestSaveOrgAccountingPreferences: () => {},
    receiveSaveOrgAccountingPreferences: () => {},
    saveOrgAccountingPreferencesError: () => {},
  },
  extraReducers: (builder) => {
    builder.addCase(authenticationActions.GO_TO_LOGIN, (state) => {
      state.currentlyAssumedProfile = undefined;
      state.organizationAuthToken = undefined;
      state.organizationUser = undefined;
      persistToSession(state, keysToPersist, SESSION_STORAGE_KEY_ORGS);
    });
    builder.addCase(receiveAuthorizeUser, (state) => {
      state.assumeProfileStatus = STATUS.SUCCESS;
    });
    builder.addCase(dismissModal, (state) => {
      state.upsertProfileError = undefined;
      state.upsertProfileStatus = STATUS.UNINITIALIZED;
    });
  },
});

// all actions
export const { actions } = organizationsSlice;

// individual actions
export const {
  organizationSectionEnter,
  enterSettingsSection,
  enterTeamMemberSection,
  resetTeamMemberSection,
  receiveAuthorizeOrganization,
  organizationSectionLoaded,
  resetOrganization,
  fetchOrganization,
  assumeProfile,
  assumeProfileReceive,
  assumeProfileRequest,
  unassumeProfile,
  unassumeProfileRequest,
  unassumeProfileReceive,
  addProfile,
  addProfileRequest,
  addProfileReceive,
  addProfileError,
  updateProfile,
  updateProfileRequest,
  updateProfileReceive,
  updateProfileError,
  deleteProfile,
  deleteProfileRequest,
  deleteProfileReceive,
  deleteProfileError,
  undoDeleteProfile,
  undoDeleteProfileRequest,
  undoDeleteProfileReceive,
  undoDeleteProfileError,
  fetchTeamMemberList,
  requestTeamMemberList,
  receiveTeamMemberList,
  receiveTeamMemberListError,
  updateTeamMemberAccess,
  updateTeamMemberAccessRequest,
  updateTeamMemberAccessReceive,
  updateTeamMemberAccessError,
  addTeamMember,
  addTeamMemberRequest,
  addTeamMemberReceive,
  addTeamMemberError,
  addTeamMemberAccess,
  addTeamMemberAccessRequest,
  addTeamMemberAccessReceive,
  addTeamMemberAccessError,
  enterTeamMemberEditor,
  exitTeamMemberEditor,
  exitProfileEditor,
  reinviteTeamMember,
  reinviteTeamMemberRequest,
  reinviteTeamMemberReceive,
  reinviteTeamMemberError,
  reinviteUser,
  reinviteUserRequest,
  reinviteUserReceive,
  reinviteUserError,
  inviteUser,
  inviteUserRequest,
  inviteUserReceive,
  inviteUserError,
  receiveUserOrganization,
  fetchOrgAccountingPreferences,
  requestOrgAccountingPreferences,
  receiveOrgAccountingPreferences,
  orgAccountingPreferencesError,
  deleteTeamMember,
  deleteTeamMemberRequest,
  deleteTeamMemberReceive,
  deleteTeamMemberError,
  saveAccountingPreferences,
  requestSaveAccountingPreferences,
  receiveSaveAccountingPreferences,
  saveAccountingPreferencesError,
  saveOrgAccountingPreferences,
  requestSaveOrgAccountingPreferences,
  receiveSaveOrgAccountingPreferences,
  saveOrgAccountingPreferencesError,
} = organizationsSlice.actions;

export default organizationsSlice.reducer;
